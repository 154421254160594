
import { defineComponent, ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { toDateFilter } from "@/util/oauth";
import {
  getOrderList,
  updateOrder,
  getTotal,
  orderFormModel,
} from "@/api/order";
import { useRouter } from "vue-router";
import { OrderStatusEnum, Order, HomeTotal } from "@/api/apiModel";

export default defineComponent({
  name: "home",
  components: {},
  setup() {
    let totalData = ref<HomeTotal>({
      orderFinishnetWaitTotal: 0,
      orderFinishedTotal: 0,
      orderTotal: 0,
    });
    const getAll = async () => {
      const res = await getTotal();
      if (res.data != undefined) {
        totalData.value = res.data;
      }
    };
    // 获取订单列表、搜索
    let orderList = ref([]);
    const getOrder = async (): Promise<void> => {
      let form: orderFormModel;
      form = {
        search: "",
        sort: "desc",
        pageSize: 10,
        pageNum: 1,
      };
      const res = await getOrderList(form);
      if (res.code == 200) {
        orderList.value = res.data.content;
      } else {
        ElMessage.error("获取订单失败");
      }
    };
    // 全部
    const url = useRouter();
    const getRecord = () => {
      url.push("/record");
    };

    // 开始看脸
    const editWorkbench = async (order: Order) => {
      order.orderStatus = OrderStatusEnum.operating;
      await updateOrder(order, "");
      const { href } = url.resolve({
        path: `/operateEditor`,
        query: {
          gid: "",
          gname: "",
          id: order.id,
          type: "workbench",
        },
      });
      window.open(href, "_blank");
    };
    // 导出
    const exportPdf = (id: string) => {
      const { href } = url.resolve({
        path: `/export`,
        query: {
          id: id,
          type: "workbench",
          isExport: "preview",
        },
      });
      window.open(href, "_blank");
    };

    const editAIReporty = async (order: Order) => {
      const { href } = url.resolve({
        path: `/operateEditor`,
        query: {
          gid: "",
          gname: "",
          id: order.id,
          type: "workbench",
          ai: "true",
          image: order.faceImgName[0],
        },
      });
      window.open(href, "_blank");
    };

    onMounted(async () => {
      getAll();
      await getOrder();
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "visible") {
          getOrder();
        }
      });
    });
    const convertDate = (time: string) => {
      return toDateFilter(time);
    };
    return {
      orderList,
      convertDate,
      getRecord,
      exportPdf,
      editWorkbench,
      totalData,
      editAIReporty,
    };
  },
});
